import React from "react";
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "../../assets/admin/css/styles.css";
import "../../assets/admin/js/scripts.js";
import Dashboard from "../../components/admin/Dashboard.js";
import Profile from "../../components/admin/Profile.js";
import Category from "../../components/admin/Category.js";
import ViewCategory from "../../components/admin/category/ViewCategory.js";
import AddProduct from "../../components/admin/products/AddProduct.js";
import ViewProduct from "../../components/admin/products/ViewProduct.js";
import OrderStatus from "../../components/admin/orders/OrderStatus.js";
import OrderList from "../../components/admin/orders/OrderList.js";
import OrderTracking from "../../components/admin/orders/OrderTracking.js";
import ViewPromotionalImg from "../../components/admin/products/ViewPromotionalImg.js";
import CreatePromotionalOffers from "../../components/admin/products/CreatePromotionalOffers.js";
import AddSubCategory from "../../components/admin/category/AddSubCategory.js";
import ViewSubcategory from "../../components/admin/category/ViewSubcategory.js";
import AddNotification from "../../components/admin/pages/AddNotification.js";
import EditProducts from "../../components/admin/products/EditProducts.js";
import { Link, useNavigate } from "react-router-dom";
import EditCategory from "../../components/admin/category/EditCategory.js";
import AddPromotionalImg from "../../components/admin/products/AddPromotionalImg.js";
import UpdateVersion from "../../components/admin/pages/UpdateVersion.js";
import AddDeliveryCost from "../../components/admin/pages/AddDeliveryCost.js";
import Delivered from "../../components/admin/status/Delivered.js";
import Cancelled from "../../components/admin/status/Cancelled.js";
import Shipped from "../../components/admin/status/Shipped.js";
import Processing from "../../components/admin/status/Processing.js";
import Accepted from "../../components/admin/status/Accepted.js";
import NotAccepted from "../../components/admin/status/NotAccepted.js";
import DropdownOfStatus from "../../components/admin/status/DropdownOfStatus.js";
import AddBankDetails from "../../components/admin/pages/AddBankDetails.js";
import ViewTrendingProducts from "../../components/admin/products/ViewTrandingProduct.js";
import AddCoupon from "../../components/admin/pages/AddCoupon.js";
import GetCoupons from "../../components/admin/pages/GetCoupon.js";
import Reports from "../../components/admin/pages/reports.js";
// import ShippingLabel from "../../components/admin/pages/ShippingLabel.js";
import AddBrand from "../../components/admin/category/AddBrand.js";
import GetAllBrands from "../../components/admin/category/ViewBrand.js";
import AddSpecifications from "../../components/admin/category/AddSpecification.js";
import GetAllspecificationkeys from "../../components/admin/category/ViewSpecification.js";
import AddFlashSale from "../../components/admin/pages/AddFlashSale.js";
import GetFlashSale from "../../components/admin/pages/GetFlashSale.js";
import BulkProductUpload from "../../components/admin/products/BulkProductUpload.js";
import ReferralFeature from "../../components/admin/pages/ReferralFeature.js";
import GiftProductDetails from "../../components/admin/pages/ViewReferralFeature.js";
import ImageUploading from "../../components/admin/products/ImageUploading.js";
import ViewImages from "../../components/admin/products/ViewImage.js";
import Analytics from "../../components/admin/pages/AppAnalytics.js";

const MasterLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      navigate("/");
    }
  }, []);

  const routes = [
    {
      path: "/admin",
      name: "Admin",
      element: <Navigate to="/admin/dashboard" />,
    },
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      element: <Dashboard />,
    },
    {
      path: "/admin/profile",
      name: "Profile",
      element: <Profile />,
    },
    {
      path: "/admin/add-Category",
      name: "add-Category",
      element: <Category />,
    },
    {
      path: "/admin/ViewCategory",
      name: "ViewCategory",
      element: <ViewCategory />,
    },
    {
      path: "/admin/AddProduct",
      name: "AddProduct",
      element: <AddProduct />,
    },
    {
      path: "/admin/ViewProduct",
      name: "ViewProduct",
      element: <ViewProduct />,
    },
    {
      path: "/admin/OrderList",
      name: "OrderList",
      element: <OrderList />,
    },
    {
      path: "/admin/OrderTracking",
      name: "OrderTracking",
      element: <OrderTracking />,
    },
    {
      path: "/admin/OrderStatus",
      name: "OrderStatus",
      element: <OrderStatus />,
    },
    {
      path: "/admin/CreatePromotionalOffers",
      name: "CreatePromotionalOffers",
      element: <CreatePromotionalOffers />,
    },
    {
      path: "/admin/ViewPromotional",
      name: "ViewPromotionalOffers",
      element: <ViewPromotionalImg />,
    },
    {
      path: "/admin/AddSubCategory",
      name: "AddSubCategory",
      element: <AddSubCategory />,
    },
    {
      path: "/admin/ViewSubcategory",
      name: " ViewSubcategory",
      element: <ViewSubcategory />,
    },
    {
      path: "/admin/AddBrand",
      name: "AddBrand",
      element: <AddBrand />,
    },

    {
      path: "/admin/GetAllBrands",
      name: "GetAllBrands",
      element: <GetAllBrands />,
    },

    {
      path: "/admin/AddSpecifications",
      name: "AddSpecifications",
      element: <AddSpecifications />,
    },

    {
      path: "/admin/AddNotification",
      name: "AddNotification",
      element: <AddNotification />,
    },
    {
      path: "/admin/AddPromotionalImg",
      name: "AddPromotionalImg",
      element: <AddPromotionalImg />,
    },
    {
      path: "/user/ViewTrandingProduct",
      name: "ViewTrandingProduct",
      element: <ViewTrendingProducts />,
    },

    {
      path: "/admin/BulkProductUpload",
      name: "BulkProductUpload",
      element: <BulkProductUpload />,
    },

    {
      path: "/admin/ImageUploading",
      name: "ImageUploading",
      element: <ImageUploading />,
    },

    {
      path: "/admin/ViewImages",
      name: "ViewImages",
      element: <ViewImages />,
    },

    {
      path: "/admin/AddCoupon",
      name: "AddCoupon",
      element: <AddCoupon />,
    },

    {
      path: "/admin/GetCoupons",
      name: "GetCoupons",
      element: <GetCoupons />,
    },

    {
      path: "/admin/AddFlashSale",
      name: "AddFlashSale",
      element: <AddFlashSale />,
    },

    {
      path: "/admin/GetFlashSale",
      name: "GetFlashSale",
      element: <GetFlashSale />,
    },

    {
      path: "/admin/ReferralFeature",
      name: "ReferralFeature",
      element: <ReferralFeature />,
    },

    {
      path: "/admin/GiftProductDetails",
      name: "GiftProductDetails",
      element: <GiftProductDetails />,
    },

    {
      path: "/admin/UpdateVersion",
      name: "UpdateVersion",
      element: <UpdateVersion />,
    },
    {
      path: "/admin/edit/:id",
      name: "EditProducts",
      element: <EditProducts />,
    },

    {
      path: "/admin/EditCategory/:id",
      name: "EditCategory ",
      element: <EditCategory />,
    },
    {
      path: "/admin/AddDeliveryCost",
      name: "AddDeliveryCost",
      element: <AddDeliveryCost />,
    },
    {
      path: "/admin/Delivered",
      name: "Delivered",
      element: <Delivered />,
    },
    {
      path: "/admin/Cancelled",
      name: "Cancelled",
      element: <Cancelled />,
    },
    {
      path: "/admin/Shipped",
      name: "Shipped",
      element: <Shipped />,
    },
    {
      path: "/admin/Processing",
      name: "Processing",
      element: <Processing />,
    },
    {
      path: "/admin/Accepted",
      name: "Accepted",
      element: <Accepted />,
    },
    {
      path: "/admin/NotAccepted",
      name: "NotAccepted",
      element: <NotAccepted />,
    },
    {
      path: "/admin/DropdownOfStatus",
      name: "DropdownOfStatus",
      element: <DropdownOfStatus />,
    },
    {
      path: "/admin/AddBankDetails",
      name: "AddBankDetails",
      element: <AddBankDetails />,
    },
    {
      path: "/admin/AddBankDetails",
      name: "AddBankDetails",
      element: <AddBankDetails />,
    },
    {
      path: "/admin/Analytics",
      name: "Analytics",
      element: <Analytics />,
    },
  ];

  // console.log('ViewCategory');
  // console.log('Routes:', routes);

  return (
    <div className="sb-nav-fixed">
      <Navbar />
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main>
            <Routes>
              <Route path="admin" element={<Navigate to="/admin" />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="add-Category" element={<Category />} />
              <Route path="ViewCategory" element={<ViewCategory />} />
              <Route path="AddProduct" element={<AddProduct />} />
              <Route path="ViewProduct" element={<ViewProduct />} />
              <Route path="OrderList" element={<OrderList />} />
              <Route path="OrderTracking" element={<OrderTracking />} />
              <Route path="OrderStatus" element={<OrderStatus />} />
              <Route
                path="CreatePromotionalOffers"
                element={<CreatePromotionalOffers />}
              />
              <Route path="ViewPromotional" element={<ViewPromotionalImg />} />
              <Route path="AddSubCategory" element={<AddSubCategory />} />
              <Route path="ViewSubcategory" element={<ViewSubcategory />} />
              <Route path="AddNotification" element={<AddNotification />} />
              <Route path="AddPromotionalImg" element={<AddPromotionalImg />} />
              <Route path="UpdateVersion" element={<UpdateVersion />} />
              <Route path="/edit/:id" element={<EditProducts />} />
              <Route path="/EditCategory/:id" element={<EditCategory />} />
              <Route path="/AddDeliveryCost" element={<AddDeliveryCost />} />
              <Route path="/Delivered" element={<Delivered />} />
              <Route path="/Cancelled" element={<Cancelled />} />
              <Route path="/Shipped" element={<Shipped />} />
              <Route path="/Processing" element={<Processing />} />
              <Route path="/Accepted" element={<Accepted />} />
              <Route path="/NotAccepted" element={<NotAccepted />} />
              <Route path="/DropdownOfStatus" element={<DropdownOfStatus />} />
              <Route path="/AddBankDetails" element={<AddBankDetails />} />
              <Route
                path="/ViewTrandingProduct"
                element={<ViewTrendingProducts />}
              />
              <Route path="/AddCoupon" element={<AddCoupon />} />
              <Route path="/GetCoupon" element={<GetCoupons />} />
              <Route path="/Reports" element={<Reports />} />
              {/* <Route path="/ShippingLabel" element={<ShippingLabel />} /> */}
              <Route path="/AddBrand" element={<AddBrand />} />
              <Route path="GetAllBrands" element={<GetAllBrands />} />
              <Route
                path="/AddSpecifications"
                element={<AddSpecifications />}
              />
              <Route
                path="/GetAllSpecificationkeys"
                element={<GetAllspecificationkeys />}
              />

              <Route path="/AddFlashSale" element={<AddFlashSale />} />
              <Route path="/GetFlashSale" element={<GetFlashSale />} />
              <Route
                path="/BulkProductUpload"
                element={<BulkProductUpload />}
              />
              <Route path="/ReferralFeature" element={<ReferralFeature />} />
              <Route
                path="/GiftProductDetails"
                element={<GiftProductDetails />}
              />
              <Route path="/ImageUploading" element={<ImageUploading />} />
              <Route path="/ViewImages" element={<ViewImages />} />
              <Route path="/Analytics" element={<Analytics />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MasterLayout;
