import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const GetAllBrands = () => {
  const [brands, setBrands] = useState([]);
  const [error, setError] = useState(null);
  const [editBrand, setEditBrand] = useState(null);
  const [newBrandName, setNewBrandName] = useState("");

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
        );

        if (response.data && Array.isArray(response.data)) {
          setBrands(response.data);
          console.log("API Response:", response.data);
        } else if (
          response.data &&
          response.data.brands &&
          Array.isArray(response.data.brands)
        ) {
          setBrands(response.data.brands);
        } else {
          setError("Unexpected response format.");
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
        setError("Failed to load brands. Please try again.");
      }
    };

    fetchBrands();
  }, []);

  const handleEditClick = (brand) => {
    setEditBrand(brand);
    setNewBrandName(brand.name);
  };

  // const handleAddSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${apiConfig.baseUrl}${apiConfig.AddBrand}`, { name: newBrandName });
  //     console.log('Add Response:', response.data);
  //     setBrands([...brands, response.data]);
  //     setNewBrandName('');
  //     alert('Brand added successfully!');
  //   } catch (error) {
  //     console.error('Error adding brand:', error);
  //     alert('Failed to add brand. Please try again.');
  //   }
  // };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!editBrand) return;

    try {
      const url = `${apiConfig.baseUrl}${apiConfig.UpdateBrand}/${editBrand._id}`;
      const response = await axios.put(url, { name: newBrandName });
      console.log("Update Response:", response.data);

      setBrands((prevBrands) =>
        prevBrands.map((brand) =>
          brand._id === editBrand._id ? { ...brand, name: newBrandName } : brand
        )
      );

      setEditBrand(null);
      setNewBrandName("");
      alert("Brand updated successfully!");
    } catch (error) {
      console.error("Error updating brand:", error);
      alert("Failed to update brand. Please try again.");
    }
  };

  const handleDeleteBrand = async (brandId) => {
    try {
      const url = `${apiConfig.baseUrl}${apiConfig.DeleteBrand}/${brandId}`;
      const response = await axios.delete(url);
      console.log("Delete Response:", response.data);

      setBrands(brands.filter((brand) => brand._id !== brandId));
      alert("Brand deleted successfully!");
    } catch (error) {
      console.error("Error deleting brand:", error);
      alert("Failed to delete brand. Please try again.");
    }
  };

  return (
    <div style={{
      margin: '40px auto',
      maxWidth: '800px',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f9f9f9',
      fontFamily: 'Arial, sans-serif'
    }}>
      <h2 style={{ color: '#333', marginBottom: '20px', textAlign: "center" }}>All Brands</h2>
  
      {error && <p style={{ color: 'red' }}>{error}</p>}
  
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>Brand Name</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>Image</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {brands.length > 0 ? (
            brands.map((brand) => (
              <tr key={brand._id}>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                  {editBrand && editBrand._id === brand._id ? (
                    <form onSubmit={handleUpdateSubmit} style={{ display: 'flex', gap: '10px' }}>
                      <input
                        type="text"
                        value={newBrandName}
                        onChange={(e) => setNewBrandName(e.target.value)}
                        required
                        style={{
                          padding: '8px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          width: '100%',
                        }}
                      />
                      <button
                        type="submit"
                        style={{
                          padding: '8px 16px',
                          backgroundColor: '#007bff',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={() => setEditBrand(null)}
                        style={{
                          padding: '8px 16px',
                          backgroundColor: '#6c757d',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </button>
                    </form>
                  ) : (
                    brand.name
                  )}
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                  {brand.image && brand.image.length > 0 ? (
                    <img 
                      src={brand.image[0]} 
                      alt={brand.name} 
                      style={{ width: '80px', height: 'auto', borderRadius: '4px' }} 
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                  {!editBrand && (
                    <>
                      <button
                        onClick={() => handleEditClick(brand)}
                        style={{
                          marginRight: '10px',
                          padding: '5px 10px',
                          backgroundColor: '#ffc107',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteBrand(brand._id)}
                        style={{
                          padding: '5px 10px',
                          backgroundColor: '#dc3545',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={{ padding: '10px', textAlign: 'center' }}>No brands found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  
};

export default GetAllBrands;
