// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   LineElement,
//   PointElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// // Register necessary ChartJS components
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   LineElement,
//   PointElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const AnalyticsPanel = () => {
//   const [analyticsData, setAnalyticsData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [eventName, setEventName] = useState("app_open"); // Default to 'app_open'
//   const [timePeriod, setTimePeriod] = useState("7daysAgo");
//   const [error, setError] = useState(null);

//   // Fetch analytics data based on selected event and time period
//   useEffect(() => {
//     setLoading(true);
//     setError(null);

//     axios
//       .get(
//         `https://www.bharatcod.com/admin/getAnalytics?timePeriod=${timePeriod}&eventName=${eventName}`
//       )
//       .then((response) => {
//         setAnalyticsData(response.data.data);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching analytics data:", error);
//         setError("Failed to fetch analytics data");
//         setLoading(false);
//       });
//   }, [timePeriod, eventName]);

//   // Prepare chart data
//   const chartData = {
//     labels:
//       analyticsData?.rows?.map(
//         (row) => row.dimensionValues[0]?.value || "Unknown Date"
//       ) || [],
//     datasets: [
//       {
//         label: `${eventName} Event Count`,
//         data:
//           analyticsData?.rows?.map(
//             (row) => parseFloat(row.metricValues[0]?.value) || 0
//           ) || [],
//         borderColor: "rgba(75, 192, 192, 1)",
//         backgroundColor: "rgba(75, 192, 192, 0.2)",
//         fill: true,
//       },
//       {
//         label: `Active Users for ${eventName}`,
//         data:
//           analyticsData?.rows?.map(
//             (row) => parseFloat(row.metricValues[1]?.value) || 0
//           ) || [],
//         borderColor: "rgba(255, 99, 132, 1)",
//         backgroundColor: "rgba(255, 99, 132, 0.2)",
//         fill: true,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     plugins: {
//       title: {
//         display: true,
//         text: `${eventName} Analytics`,
//       },
//       legend: {
//         position: "top",
//       },
//     },
//   };

//   if (loading) {
//     return <div>Loading analytics data...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div>
//       <h1>Custom Event Analytics</h1>

//       {/* Time Period Selector */}
//       <div>
//         <label htmlFor="timePeriod">Select Time Period:</label>
//         <select
//           id="timePeriod"
//           value={timePeriod}
//           onChange={(e) => setTimePeriod(e.target.value)}
//         >
//           <option value="7daysAgo">Last 7 Days</option>
//           <option value="weekly">Last 30 Days (Weekly)</option>
//           <option value="monthly">Last 60 Days (Monthly)</option>
//           <option value="yearly">Last 365 Days (Yearly)</option>
//         </select>
//       </div>

//       {/* Event Selector */}
//       <div>
//         <label htmlFor="eventName">Select Event:</label>
//         <select
//           id="eventName"
//           value={eventName}
//           onChange={(e) => setEventName(e.target.value)}
//         >
//           <option value="app_open">App Open</option>
//           <option value="add_to_cart">Add to Cart</option>
//           <option value="order_placed">Order Placed</option>
//         </select>
//       </div>

//       {/* Chart */}
//       <Line data={chartData} options={options} />
//     </div>
//   );
// };

// export default AnalyticsPanel;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { format, parse } from 'date-fns';

// Register necessary ChartJS components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const AnalyticsPanel = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [eventName, setEventName] = useState('app_open'); // Default to 'app_open'
  const [timePeriod, setTimePeriod] = useState('7daysAgo');
  const [error, setError] = useState(null);

  // Fetch analytics data based on selected event and time period
  useEffect(() => {
    setLoading(true);
    setError(null);

    axios
      .get(`https://www.bharatcod.com/admin/getAnalytics?timePeriod=${timePeriod}&eventName=${eventName}`)
      .then((response) => {
        setAnalyticsData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching analytics data:', error);
        setError('Failed to fetch analytics data');
        setLoading(false);
      });
  }, [timePeriod, eventName]);

  // Prepare chart data
  const chartData = {
    labels: analyticsData?.rows?.map((row) => {
      const rawDate = row.dimensionValues[0]?.value || 'Unknown Date';
      try {
        // Assume the date format from the API is 'YYYYMMDD'
        const parsedDate = parse(rawDate, 'yyyyMMdd', new Date());
        return format(parsedDate, 'MMM dd, yyyy'); // Format as 'Month Day, Year'
      } catch {
        return rawDate; // Fallback to raw date if parsing fails
      }
    }) || [],
    datasets: [
      {
        label: `${eventName} Event Count`,
        data: analyticsData?.rows?.map((row) => parseFloat(row.metricValues[0]?.value) || 0) || [],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
      {
        label: `Active Users for ${eventName}`,
        data: analyticsData?.rows?.map((row) => parseFloat(row.metricValues[1]?.value) || 0) || [],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${eventName} Analytics`,
      },
      legend: {
        position: 'top',
      },
    },
  };

  const handleRefresh = () => {
    setLoading(true);
    setError(null);
    axios
      .get(`https://www.bharatcod.com/admin/getAnalytics?timePeriod=${timePeriod}&eventName=${eventName}`)
      .then((response) => {
        setAnalyticsData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching analytics data:', error);
        setError('Failed to fetch analytics data');
        setLoading(false);
      });
  };

  if (loading) {
    return <div style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px' }}>Loading analytics data...</div>;
  }

  if (error) {
    return <div style={{ textAlign: 'center', color: 'red', fontSize: '18px', marginTop: '20px' }}>{error}</div>;
  }

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center', color: '#333' }}> Event Analytics</h1>

      {/* Time Period Selector */}
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="timePeriod" style={{ fontWeight: 'bold', marginRight: '10px' }}>Select Time Period:</label>
        <select
          id="timePeriod"
          value={timePeriod}
          onChange={(e) => setTimePeriod(e.target.value)}
          style={{
            padding: '8px',
            fontSize: '16px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            width: '150px',
          }}
        >
          <option value="7daysAgo">Last 7 Days</option>
          <option value="weekly">Last 30 Days (Weekly)</option>
          <option value="monthly">Last 60 Days (Monthly)</option>
          <option value="yearly">Last 365 Days (Yearly)</option>
        </select>
      </div>

      {/* Event Selector */}
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="eventName" style={{ fontWeight: 'bold', marginRight: '10px' }}>Select Event:</label>
        <select
          id="eventName"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          style={{
            padding: '8px',
            fontSize: '16px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            width: '150px',
          }}
        >
          <option value="app_open">App Open</option>
          <option value="add_to_cart">Add to Cart</option>
          <option value="order_placed">Order Placed</option>
        </select>
      </div>

      {/* Refresh Button */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <button
          onClick={handleRefresh}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Refresh Data
        </button>
      </div>

      {/* Chart */}
      <div style={{ maxWidth: '900px', margin: '0 auto' }}>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default AnalyticsPanel;
