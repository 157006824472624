import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const GetCoupons = () => {
  const [coupons, setCoupons] = useState([]);

  console.log("coupons", coupons);

  useEffect(() => {
    fetchCoupons();
  }, []);
  const fetchCoupons = async () => {
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.GetCoupons}`
      );
      setCoupons(response?.data?.coupons);
    } catch (error) {
      console.error(
        "Error fetching coupons:",
        error.response?.data || error.message
      );
      alert(
        `Error fetching coupons: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handleDelete = async (couponId) => {
    {
      try {
        await axios.delete(
          `${apiConfig.baseUrl}${apiConfig.DeleteCoupons}${couponId}`
        );
        setCoupons(coupons.filter((coupon) => coupon._id !== couponId));
        alert("Coupon deleted successfully");
      } catch (error) {
        console.error(
          "Error deleting coupon:",
          error.response?.data || error.message
        );
      }
    }
  };

  const handleRefresh = async () => {
    await fetchCoupons();
  };

  return (
    <div style={styles.container}>
      <button
        onClick={handleRefresh}
        style={{
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          backgroundColor: "#28A745",
          color: "#fff",
          fontSize: "16px",
          cursor: "pointer",
          margin: "0 20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#218838")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#28A745")}
      >
        Refresh Data
      </button>
      <h2 style={styles.header}>Coupons List</h2>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Title</th>
            <th style={styles.th}>Code</th>
            <th style={styles.th}>Description</th>
            <th style={styles.th}>UsageLimit</th>
            <th style={styles.th}>Products</th>
            <th style={styles.th}>Category</th>
            <th style={styles.th}>Brand</th>
            <th style={styles.th}>Images</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {coupons?.map((coupon) => (
            <tr key={coupon._id}>
              <td style={styles.td}>{coupon?.Title}</td>
              <td style={styles.td}>{coupon.code}</td>
              <td style={styles.td}>{coupon.description}</td>
              <td style={styles.td}>{coupon.usageLimit}</td>
              <td style={styles.td}>{coupon.applicableProducts}</td>
              <td style={styles.td}>{coupon.applicableCategories[0]?.name}</td>
              <td style={styles.td}>{coupon.applicableBrands[0]?.name}</td>

              <td style={styles.td}>
                {coupon.image && coupon.image.length > 0
                  ? coupon.image.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Coupon ${coupon.title} image ${index + 1}`}
                        style={styles.image}
                      />
                    ))
                  : "No Image Available"}
              </td>
              <td style={styles.td}>
                <button
                  style={styles.deleteButton}
                  onClick={() => handleDelete(coupon._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    color: "#333",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    borderBottom: "2px solid #ddd",
    padding: "10px",
    textAlign: "left",
    fontWeight: "bold",
  },
  td: {
    borderBottom: "1px solid #ddd",
    padding: "10px",
  },
  deleteButton: {
    backgroundColor: "#e74c3c",
    color: "#fff",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
    borderRadius: "4px",
  },
  image: {
    width: "100px",
    height: "auto",
    marginRight: "10px",
  },
};

export default GetCoupons;
